import {
  Container,
  Grid,
  IconButton,
  Button,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import images from "../../../assets/images";
import ServiceCard from "../../components/ServiceCard";
import Services from "../Services/Services.json";
import Testimonials from "../../shared/Testimonials.json";
import Industries from "../Industries/Industries.json";
import IndustryCard from "../../components/IndustryCard";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import Aos from "aos";
import Slider from "react-slick";
import { Link as RouterLink } from "react-router-dom";
import TestimonialCard from "../../components/TestimonialCard";
import { Helmet } from "react-helmet-async";

interface serviceCardProps {
  icon: string;
  title: string;
  description: string;
  link: string;
  iconBg: string;
}

interface industryCardProps {
  bgImage: string;
  title: string;
  link: string;
}

interface testimonialCardProps {
  feedback: string;
  name: string;
  role: string;
  companyLogo: string;
  companyName: string;
}

interface SliderButtonProps {
  className: string;
  onClick: () => void;
}

const PrevButton: React.FC<SliderButtonProps> = ({ className, onClick }) => (
  <IconButton size="small" className={className} onClick={onClick}>
    <img src={images.NavArrowLeft} alt="Previous" />
    <img src={images.NavArrowLeftWhite} className="active" alt="Previous" />
  </IconButton>
);

const NextButton: React.FC<SliderButtonProps> = ({ className, onClick }) => (
  <IconButton size="small" className={className} onClick={onClick}>
    <img src={images.NavArrowRight} alt="Next" />
    <img src={images.NavArrowRightWhite} className="active" alt="Next" />
  </IconButton>
);

const Home: React.FC = () => {
  const [startCounter, setStartCounter] = useState(false);
  const faqDataPage = [
    {
      id: "panel1",
      question: "Why choose unique IT solution as service provider?",
      answer: "Unique IT Solution delivers custom, cutting-edge technology and innovative approaches to provide top-tier solutions. Our expertise in Agile software development, combined with a customer-centric approach, enables us to deeply understand various frameworks, libraries, programming languages, and platforms, ensuring the delivery of the best web software solutions"
    },
    {
      id: "panel2",
      question: "What software development services are included in your offerings?",
      answer: "At Unique IT Solution, we provide a comprehensive range of software development services, including Custom Software Development,Work Flow Automation,Web development,App Development and similar services.Our services are suited to various industries, and we make sure they are in line with your business goals and growth plans. "
    },
    {
      id: "panel3",
      question: "Which technology we use for development?",
      answer: "We provide web and software development services using a variety of technologies and programming languages. The choice of technology is tailored to each client’s needs to ensure it meets their goals and boosts performance. While clients make the final decision, we guide them with expert advice based on their business needs, budget, and future growth to ensure they get the best possible solution. "
    },
    {
      id: "panel4",
      question: "How would you provide custom software development?",
      answer: "We begin by understanding your business needs and then design a custom software solution just for you. Our team builds the software using the latest technologies, tests it thoroughly, and helps with deployment. We also provide ongoing support and maintenance to ensure it continues to meet your business goals effectively."
    },

    {
      id: "panel5",
      question: "How to contact us?",
      answer: "You can fill out our contact form on our website, and we will get back to you as soon as possible.Otherwise you are welcome to visit us at our office."
    },
  ]

  const handleStartCounter = () => {
    setStartCounter(true);
  };

  let servicesSliderOptions = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <PrevButton className="slick-prev" onClick={() => { }} />,
    nextArrow: <NextButton className="slick-next" onClick={() => { }} />,
  };

  let industriesSliderOptions = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <PrevButton className="slick-prev" onClick={() => { }} />,
    nextArrow: <NextButton className="slick-next" onClick={() => { }} />,
  };

  let testimonialsSliderOptions = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    prevArrow: <PrevButton className="slick-prev" onClick={() => { }} />,
    nextArrow: <NextButton className="slick-next" onClick={() => { }} />,
  };

  let clientsSliderOptions = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    variableWidth: true,
  };

  useEffect(() => {
    Aos.init({
      duration: 700,
      once: true,
    });
  }, []);

  return (
    <>
      {/* <Helmet>
        <title>Home | Unique IT Solutions</title>
      </Helmet> */}
      <Helmet>
        {/* Character set */}
        <meta charSet="UTF-8" />
        <title>Home | Unique IT Solutions</title>
        {/* Viewport settings */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Meta description for SEO */}
        <meta
          name="description"
          content="Welcome to Unique IT Solution - your partner in cutting-edge IT services. We specialize in custom software development, web and mobile applications, and workflow automation to drive innovation and efficiency in your business."
        />


        <meta
          name="keywords"
          content="Unique IT Solution, IT services, custom software development, web development, mobile app development, workflow automation, technology solutions, business technology, IT innovation"
        />


        <meta name="author" content="Unique IT Solution" />


        <meta
          property="og:title"
          content="Unique IT Solution - Leading IT Services and Technology Solutions"
        />
        <meta
          property="og:description"
          content="Explore Unique IT Solution's range of IT services including software development, web and mobile app creation, and workflow automation designed to enhance your business's efficiency and success."
        />
        <meta property="og:url" content="https://uniqueitsolution.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://uniqueitsolution.com/assets/images/home-page-banner.png"
        />
      </Helmet>

      {/* banner start */}
      <section className="banner hero-banner mb-9" data-aos="fade-in">
        <Container maxWidth="xl">
          <div className="banner-content">  
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Your Challenges <span>Our Experties</span>
            </Typography>
            <Typography
              component="p"
              variant="body1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Welcome to Unique IT Solution, your foremost partner in innovative
              IT services. We lead with state-of-the-art solutions, setting
              industry benchmarks and revolutionizing business activities.
            </Typography>
            <RouterLink to={`${process.env.PUBLIC_URL}/services`}>
              <Button
                variant="contained"
                color="primary"
                data-aos="fade-up"
                data-aos-delay="300"
                title="View Our Services"
              >
                <span className="text">View Our Services</span>
              </Button>
            </RouterLink>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        {/* about it solution start */}
        <section className="about-it-solution mb-9">
          <Grid container spacing={5}>
            <Grid item lg={5} xs={12} data-aos="fade-up">
              <div className="image-wrapper">
                <img
                  loading="lazy"
                  src={images.AboutItSolution}
                  alt="About IT Solutions That are Unique"
                />
              </div>
            </Grid>
            <Grid item lg={7} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Typography component="h3" variant="h3" className="pb-4">
                About IT Solutions That are Unique
              </Typography>
              <Typography component="p" variant="body1">
                Since its establishment in 2021, Unique IT Solution has rapidly
                grown to over 20 passionate members. Our mission is to provide
                superior IT solutions tailored to diverse business needs,
                driving growth and transformation. <br />
                <br />
                With over four years of expertise in web, app, and custom
                software development, we excel in creating bespoke products that
                keep our clients ahead. We deliver custom-made solutions to meet
                specific requirements.
                <br />
                <br /> Our support teams go beyond delivering perfect products.
                They offer exclusive after-sales services, ensuring our clients'
                continuous success.
              </Typography>
            </Grid>
          </Grid>
        </section>
        {/* about it solution end */}

        {/* counter start */}
        <section className="counter mb-9">
          <ul>
            <Waypoint onEnter={handleStartCounter} />
            <li>
              {startCounter ? (
                <CountUp
                  start={0}
                  end={40}
                  duration={3}
                  suffix="+"
                  startOnMount={false}
                />
              ) : (
                <span>0</span>
              )}
              <Typography component="p" variant="body1">
                Clients
              </Typography>
            </li>
            <li>
              {startCounter ? (
                <CountUp
                  start={0}
                  end={62}
                  duration={3}
                  suffix="+"
                  startOnMount={false}
                />
              ) : (
                <span>0</span>
              )}
              <Typography component="p" variant="body1">
                Projects Completed
              </Typography>
            </li>
            <li>
              {startCounter ? (
                <CountUp
                  start={0}
                  end={20}
                  duration={3}
                  suffix="+"
                  startOnMount={false}
                />
              ) : (
                <span>0</span>
              )}
              <Typography component="p" variant="body1">
                Experts Team
              </Typography>
            </li>
            <li>
              {startCounter ? (
                <CountUp
                  start={0}
                  end={3}
                  duration={3}
                  suffix="+"
                  startOnMount={false}
                />
              ) : (
                <span>0</span>
              )}
              <Typography component="p" variant="body1">
                Years Experience
              </Typography>
            </li>
          </ul>
        </section>
        {/* counter end */}
      </Container>
      {/* our services start */}
      <section
        className="our-services slider-card outer-card mb-9"
        data-aos="fade-in"
      >
        <Container
          maxWidth="xl"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div className="top pb-6">
            <Typography
              component="h3"
              className="pb-3"
              variant="h3"
              data-aos="fade-up"
            >
              Our Services
            </Typography>
            <Typography component="p" variant="body1" data-aos="fade-up">
              We specialize in providing top-tier Web Development, Custom
              Software Solutions, and Mobile App Development, leveraging the
              latest technologies to deliver exceptional results.
            </Typography>
          </div>
          <div data-aos="fade-up">
            <Slider {...servicesSliderOptions}>
              {Services.map((service: serviceCardProps, index: number) => (
                <ServiceCard
                  key={index}
                  icon={images[service.icon]}
                  title={service.title}
                  description={service.description}
                  link={service.link}
                  iconBg={service.iconBg}
                  from="home"
                />
              ))}
            </Slider>
          </div>
          <RouterLink to={`${process.env.PUBLIC_URL}/services`}>
            <Button
              variant="contained"
              endIcon={
                <img
                  loading="lazy"
                  src={images.RightArrowWhite}
                  alt="Right Arrow"
                />
              }
              title="Explore More"
              data-aos="fade-up"
            >
              <span className="text">Explore More</span>
            </Button>
          </RouterLink>
        </Container>
      </section>
      {/* our services end */}

      {/* industries we serve start */}
      <section className="industries-we-serve slider-card" data-aos="fade-up">
        <Container
          maxWidth="xl"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div className="top pb-6">
            <div className="left">
              <Typography
                component="h3"
                className="pb-3"
                variant="h3"
                data-aos="fade-up"
              >
                Industries We Serve
              </Typography>
              <Typography component="p" variant="body1" data-aos="fade-up">
                Explore how Unique IT Solution has shaped business by offering
                innovative and technology driven software solution.
              </Typography>
            </div>
          </div>
          <div data-aos="fade-up">
            <Slider {...industriesSliderOptions}>
              {Industries.map((industry: industryCardProps, index: number) => (
                <IndustryCard
                  key={index}
                  bgImage={images[industry.bgImage]}
                  title={industry.title}
                  link={industry.link}
                  from="home"
                />
              ))}
            </Slider>
          </div>
          <RouterLink to={`${process.env.PUBLIC_URL}/industries`}>
            <Button
              variant="contained"
              endIcon={
                <img
                  loading="lazy"
                  src={images.RightArrowWhite}
                  alt="Right Arrow"
                />
              }
              title="Explore More"
              data-aos="fade-up"
            >
              <span className="text">Explore More</span>
            </Button>
          </RouterLink>
        </Container>
      </section>
      {/* industries we serve end */}
      <Container maxWidth="xl">
        {/* software development technologies start */}
        <div
          className="software-development-technologies mb-9"
          data-aos="fade-up"
        >
          <div className="content">
            <Typography
              component="h4"
              className="pb-3"
              variant="h4"
              data-aos="fade-up"
            >
              Software Development Technologies
            </Typography>
            <Typography
              component="p"
              className="pb-4"
              variant="subtitle1"
              data-aos="fade-up"
            >
              We Digitize your idea. We believe in technology. We save your
              costs.
            </Typography>
            <Typography component="span" variant="body2" data-aos="fade-up">
              We create advanced software and mobile apps to simplify business
              challenges. Using the latest tech, agile methods, and DevOps, we
              deliver cost-effective solutions to boost performance. We
              specialize in various front-end and back-end technologies.
            </Typography>
            <RouterLink to={`${process.env.PUBLIC_URL}/technologies`}>
              <Button
                variant="contained"
                title="View All Technologies"
                data-aos="fade-up"
              >
                <span className="text">View All Technologies</span>
              </Button>
            </RouterLink>
          </div>
        </div>
        {/* software development technologies end */}
      </Container>
      {/* how we work start */}
      <section className="how-we-work">
        <div className="outer-card mb-9" data-aos="fade-in">
          <Container maxWidth="xl">
            <Typography
              component="h3"
              variant="h3"
              className="pb-3"
              data-aos="fade-up"
            >
              How We Work
            </Typography>
            <Typography
              component="p"
              variant="body1"
              className="pb-6"
              data-aos="fade-up"
            >
              Explore our streamlined process that ensures efficient and
              high-quality project delivery from start to finish.
            </Typography>
            <ul>
              <li data-aos="fade-up">
                <div className="step">
                  <Typography component="p">Step 1</Typography>
                  <Typography component="span" variant="subtitle2">
                    Obtain the client&#x27;s project requirements.
                  </Typography>
                </div>
              </li>
              <li data-aos="fade-up">
                <div className="step">
                  <Typography component="p">Step 2</Typography>
                  <Typography component="span" variant="subtitle2">
                    Analyze client&#x27;s business problem with paper and pen
                  </Typography>
                </div>
              </li>
              <li data-aos="fade-up">
                <div className="step">
                  <Typography component="p">Step 3</Typography>
                  <Typography component="span" variant="subtitle2">
                    Provide a plan with divergent solutions.
                  </Typography>
                </div>
              </li>
              <li data-aos="fade-up">
                <div className="step">
                  <Typography component="p">Step 4</Typography>
                  <Typography component="span" variant="subtitle2">
                    Prepare the scope document and share it with the client.
                  </Typography>
                </div>
              </li>
              <li data-aos="fade-up">
                <div className="step">
                  <Typography component="p">Step 5</Typography>
                  <Typography component="span" variant="subtitle2">
                    Estimate the project duration and share it with the clients.
                  </Typography>
                </div>
              </li>
              <li data-aos="fade-up">
                <div className="step">
                  <Typography component="p">Step 6</Typography>
                  <Typography component="span" variant="subtitle2">
                    Client Approval.
                  </Typography>
                </div>
              </li>
              <li data-aos="fade-up">
                <div className="step">
                  <Typography component="p">Step 7</Typography>
                  <Typography component="span" variant="subtitle2">
                    Design, Development & Testing
                  </Typography>
                </div>
              </li>
              <li data-aos="fade-up">
                <div className="step">
                  <Typography component="p">Step 8</Typography>
                  <Typography component="span" variant="subtitle2">
                    Live Deployment
                  </Typography>
                </div>
              </li>
              <li data-aos="fade-up">
                <div className="step">
                  <Typography component="p">Step 9</Typography>
                  <Typography component="span" variant="subtitle2">
                    Client’s Feedback
                  </Typography>
                </div>
              </li>
            </ul>
            <img
              className="illustration-1"
              src={images.HowWeWorkIllustration1}
              alt="How We Work"
              data-aos="fade-up"
            />
            <img
              className="illustration-2"
              src={images.HowWeWorkIllustration2}
              alt="How We Work"
              data-aos="fade-up"
            />
          </Container>
        </div>
      </section>
      {/* how we work end */}
      <Container maxWidth="xl">
        {/* why choose us start */}
        <section className="why-choose-us mb-9">
          <Typography
            component="h3"
            variant="h3"
            className="pb-3"
            data-aos="fade-up"
          >
            Why Choose Us
          </Typography>
          <Typography
            component="h3"
            variant="body1"
            className="pb-6"
            data-aos="fade-up"
          >
            Discover why we're your ideal partner for innovative software
            solutions and exceptional service.
          </Typography>
          <Grid container spacing={0} data-aos="fade-up">
            <Grid item lg={4} sm={6} xs={12}>
              <Card>
                <CardContent>
                  <div className="icon">
                    <img
                      loading="lazy"
                      src={images.PaperWork}
                      alt="Strongly Believe in Paper Work And Analysis"
                    />
                  </div>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    className="pb-2"
                  >
                    Strongly Believe in Paper Work And Analysis
                  </Typography>
                  <Typography component="span" variant="body2">
                    We prioritize paperwork and analysis for precise planning
                    and success.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Card>
                <CardContent>
                  <div className="icon">
                    <img
                      loading="lazy"
                      src={images.BusinessCentricApproach}
                      alt="Business Centric Approach"
                    />
                  </div>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    className="pb-2"
                  >
                    Business Centric Approach
                  </Typography>
                  <Typography component="span" variant="body2">
                    We tailor solutions to meet your specific needs, ensuring
                    your satisfaction and success.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Card>
                <CardContent>
                  <div className="icon">
                    <img
                      loading="lazy"
                      src={images.CustomizedSolution}
                      alt="Customized Solution"
                    />
                  </div>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    className="pb-2"
                  >
                    Customized Solution
                  </Typography>
                  <Typography component="span" variant="body2">
                    We provide tailored software solutions designed to meet your
                    unique business needs.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Card>
                <CardContent>
                  <div className="icon">
                    <img
                      loading="lazy"
                      src={images.AgileBasedDecision}
                      alt="Agile Based Decision"
                    />
                  </div>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    className="pb-2"
                  >
                    Agile Based Decision
                  </Typography>
                  <Typography component="span" variant="body2">
                    We use agile methodologies to ensure flexible and efficient
                    project delivery.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Card>
                <CardContent>
                  <div className="icon">
                    <img
                      loading="lazy"
                      src={images.InnovativeApproach}
                      alt="Innovative Approach"
                    />
                  </div>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    className="pb-2"
                  >
                    Innovative Approach
                  </Typography>
                  <Typography component="span" variant="body2">
                    We leverage the latest technologies to create cutting-edge
                    solutions for your business.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Card>
                <CardContent>
                  <div className="icon">
                    <img
                      loading="lazy"
                      src={images.HighCustomerSatisfaction}
                      alt="High Customer Satisfaction"
                    />
                  </div>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    className="pb-2"
                  >
                    High Customer Satisfaction
                  </Typography>
                  <Typography component="span" variant="body2">
                    We prioritize delivering exceptional experiences that ensure
                    your satisfaction and success.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </section>
        {/* why choose us end */}
      </Container>
      {/* our clients start */}
      <section className="outer-card our-clients mb-9" data-aos="fade-in">
        <Container maxWidth="xl">
          <Typography
            component="h3"
            variant="h3"
            className="pb-3"
            data-aos="fade-up"
          >
            Our Clients
          </Typography>
          <Typography component="p" variant="body1" data-aos="fade-up">
            We deliver tailored solutions for start-ups, mid-size businesses,
            and large enterprises, optimizing processes across various
            industries.
          </Typography>
          <ul data-aos="fade-up">
            <li>
              <RouterLink
                to={`${process.env.PUBLIC_URL}/industries/hospitality-industry`}
                title="Hospitality Industry"
                state={{ from: "home" }}
              >
                Hospitality Industry
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to={`${process.env.PUBLIC_URL}/industries/retail-and-ecommerce-solution`}
                title="Retail & Ecommerce Solution"
                state={{ from: "home" }}
              >
                Retail & Ecommerce Solution
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to={`${process.env.PUBLIC_URL}/industries/news-and-entertainment`}
                title="News & Entertainment"
                state={{ from: "home" }}
              >
                News & Entertainment
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to={`${process.env.PUBLIC_URL}/industries/production-and-service-assurance`}
                title="Manufacturing & Processing Facilities"
                state={{ from: "home" }}
              >
                Production & Service Assurance
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to={`${process.env.PUBLIC_URL}/`}
                title="Telecommunication Media"
                state={{ from: "home" }}
              >
                Telecommunication Media
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to={`${process.env.PUBLIC_URL}/`}
                title="Logistic & Distribution"
                state={{ from: "home" }}
              >
                Logistic & Distribution
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to={`${process.env.PUBLIC_URL}/`}
                title="Food & Beverages"
                state={{ from: "home" }}
              >
                Food & Beverages
              </RouterLink>
            </li>
          </ul>
        </Container>
        <div data-aos="fade-up">
          <Slider {...clientsSliderOptions}>
            <img src={images.AsianFresh} alt="Asian Fresh" />
            <img src={images.Rotilaa} alt="Rotilaa" />
            <img src={images.Dicasa} alt="Dicasa Ceramica" />
            <img src={images.IskonGathiya} alt="Iskon Gathiya" />
            <img src={images.AppleFitting} alt="Apple Fitting" />
            <img src={images.SloveTiles} alt="Slove Tiles" />
            <img src={images.WingsCera} alt="Wings Cera" />
            <img src={images.KetoneMica} alt="Ketone Mica" />
            <img src={images.GrowmoreLaminate} alt="Growmore Laminate" />
            <img src={images.Suntouch} alt="Suntouch" />
            <img
              src={images.KingdomInternational}
              alt="Kingdom International"
            />
            <img src={images.KloudDial} alt="Kloud Dial" />
            <img src={images.Vaidehi} alt="Vaidehi" />
            <img src={images.AhmedabadMedia} alt="Ahmedabad Media" />
          </Slider>
        </div>
      </section>
      {/* our clients end */}
      <Container maxWidth="xl">
        {/* testimonials start */}
        <section
          className="testimonials mb-9"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Grid container spacing={5}>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography variant="h3" className="pb-4" component="h3">
                  What Our Clients Say
                </Typography>
                <Typography variant="body1" component="p">
                  See what our clients have to say about our IT solutions.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="slider-wrapper">
                <Slider {...testimonialsSliderOptions}>
                  {Testimonials.map(
                    (testimonial: testimonialCardProps, index: number) => (
                      <TestimonialCard
                        key={index}
                        name={testimonial.name}
                        feedback={testimonial.feedback}
                        role={testimonial.role}
                        companyLogo={testimonial.companyLogo}
                        companyName={testimonial.companyName}
                      />
                    )
                  )}
                </Slider>
              </div>
            </Grid>
          </Grid>
        </section>
        {/* testimonials end */}

        {/* engineering culture start */}
        <section className="engineering-culture mb-9">
          <Grid container spacing={5}>
            <Grid item xl={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img loading="lazy" src={images.TopTalents} alt="Top Talent" />
              </div>
            </Grid>
            <Grid item xl={6} xs={12}>
              <div className="content">
                <Typography
                  component="h3"
                  variant="h3"
                  className="pb-4"
                  data-aos="fade-up"
                >
                  Engineering Culture With Top Talents and best Methodology
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className="pb-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  At Unique IT Solution, we foster a motivated and strong
                  engineering culture that drives our employees to deliver their
                  best performance. By leveraging equal and advanced learning
                  opportunities, our team is equipped to tackle challenging
                  problems with a vision to create resilient software solutions
                  for clients. Additionally, our emphasis on comprehensive
                  business analysis ensures that we thoroughly understand client
                  needs, allowing us to deliver solutions that align perfectly
                  with their strategic goals and drive business success.
                </Typography>
                <ul data-aos="fade-up" data-aos-delay="200">
                  <li>
                    <img
                      loading="lazy"
                      src={images.OutlinedCheckmarkPrimary}
                      alt="Checkmark"
                    />
                    <Typography component="span" variant="subtitle2">
                      Agile Development
                    </Typography>
                  </li>
                  <li>
                    <img
                      loading="lazy"
                      src={images.OutlinedCheckmarkPrimary}
                      alt="Checkmark"
                    />
                    <Typography component="span" variant="subtitle2">
                      Scrum
                    </Typography>
                  </li>
                </ul>
                <RouterLink to={`${process.env.PUBLIC_URL}/about-us`}>
                  <Button
                    variant="contained"
                    endIcon={
                      <img
                        loading="lazy"
                        src={images.RightArrowWhite}
                        alt="Right Arrow"
                      />
                    }
                    title="Learn More"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <span className="text">Learn More</span>
                  </Button>
                </RouterLink>
              </div>
            </Grid>
          </Grid>
        </section>
        {/* engineering culture end */}
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default Home;